<template>
  <div class="login-wrap">
    <div class="ms-login">
      <div class="ms-title">JE Education Management</div>
      <div
        v-if="!showLoginForm && !showRenewAccessTokenSuccessfullyPanel"
        class="renewAccessTokenPanel"
      >
        <div class="des">
          Your session is expiry, please click below button to sign in.
        </div>
        <div class="login-btn">
          <el-button type="primary" @click="renewAccessToken"> Sign In </el-button>
        </div>
      </div>
      <div
        v-if="showRenewAccessTokenSuccessfullyPanel"
        class="renewAccessTokenSuccessfullyPanel"
      >
        <div v-if="showAutoCloseWindowPanel" class="des">
          Your session has been refreshed, the windows is about to closed in
          {{ this.countdown }}
          seconds or you can go to:
        </div>
        <div v-else class="des">
          Your session has been refreshed, you can manually close the tab/window or you
          can go to:
        </div>
        <ul class="quick_links">
          <li><a href="/dashboard">Home/Dashboard</a></li>
          <li><a href="/studentList">Students</a></li>
          <li><a href="/lessonList">Lessons</a></li>
          <li><a href="/receiptList">Receipts</a></li>
          <li><a href="/testReportList">Test Reports</a></li>
          <li><a href="/weeklyReportList">Weekly hw and quiz</a></li>
        </ul>
      </div>
      <el-form
        v-if="showLoginForm"
        :model="query"
        :rules="rules"
        ref="mainForm"
        label-width="0px"
        class="ms-content"
      >
        <el-form-item prop="username">
          <el-input v-model="query.username" :clearable="true" placeholder="username">
            <template #prepend>
              <el-button>
                <el-icon>
                  <user />
                </el-icon>
              </el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            placeholder="password"
            v-model="query.password"
            @keyup.enter="signIn"
            :show-password="true"
          >
            <template #prepend>
              <el-button>
                <el-icon>
                  <lock />
                </el-icon>
              </el-button>
            </template>
          </el-input>
        </el-form-item>
        <div class="login-btn">
          <el-button type="primary" @click="signIn">Login</el-button>
        </div>
        <div class="login-btn">
          <a target="blank" href="https://www.jeeducation.com.au/">
            Go to JE MATHS home page
          </a>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { postData } from "../../service/api";
import { showLoading, hideLoading } from "../../service/utils/loading";

export default {
  data() {
    return {
      controllerUrl: "/login",
      query: {
        appId: "MANAGEMENT_WEB_PORTAL",
        username: "",
        password: "",
      },
      countdown: 5,
      closeWindowInSeconds: 5,
      showLoginForm: false,
      showRenewAccessTokenSuccessfullyPanel: false,
      showAutoCloseWindowPanel: false,
      rules: {
        username: [
          {
            required: true,
            message: "Please input your username",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Please input against password for above username",
            trigger: "blur",
          },
        ],
      },
    };
  },
  async created() {
    console.log("globalSetting", this.$appSetting.vueInstance);
    if (this.$user.isFreshTokenValid()) {
      this.showLoginForm = false;
      this.showRenewAccessTokenSuccessfullyPanel = false;
    } else {
      this.showLoginForm = true;
      this.showRenewAccessTokenSuccessfullyPanel = false;
    }

    this.$store.commit("clearTags");
  },
  methods: {
    signIn() {
      this.$refs.mainForm.validate((valid) => {
        if (valid) {
          postData(this.controllerUrl, this.query).then((res) => {
            if (res.result && res.code === "200") {
              this.$message.success("Login successfully");

              this.$user.signIn(
                this.query.appId,
                res.result.user.userId,
                this.query.username,
                res.result.user.userRole,
                res.result.accessToken,
                res.result.refreshToken,
                res.result.accessTokenExpiredInMinutes,
                res.result.refreshTokenExpiredInMinutes
              );

              this.$router.push("/");
            } else {
              this.$message.error(`Login failed, error message: ${res.message}`);
            }
          });
        } else {
          this.$message.error("Please input correct username and password.");
          return false;
        }
      });
    },
    async renewAccessToken() {
      try {
        showLoading();
        if ((await this.$user.tryExchangeAccessToken()) === "OK") {
          this.showLoginForm = false;
          this.showRenewAccessTokenSuccessfullyPanel = true;
        } else {
          this.showLoginForm = true;
          this.showRenewAccessTokenSuccessfullyPanel = false;
        }
      } finally {
        hideLoading();
      }
    },
    closeCurrentWindow() {
      this.showAutoCloseWindowPanel = true;
      if (this.$appSetting.loginWindow) this.$appSetting.loginWindow.close();
      else this.showAutoCloseWindowPanel = false;
    },
  },
};
</script>

<style scoped>
.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/login-bg.jpg);
  background-size: 100%;
}
.ms-title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 1.67rem;
  color: orange;
  border-bottom: 1px solid #ddd;
}
.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 350px;
  margin: -190px 0 0 -175px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  overflow: hidden;
}
.ms-content {
  padding: 30px 30px;
}
.login-btn {
  text-align: center;
}
.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}
.login-tips {
  font-size: 1rem;
  line-height: 30px;
  color: #fff;
}
.renewAccessTokenPanel {
  padding: 30px;
}
.renewAccessTokenPanel .des {
  padding: 10px 10px 30px;
  font-size: 1.67rem;
}
.renewAccessTokenSuccessfullyPanel {
  padding: 20px 30px;
}
.renewAccessTokenSuccessfullyPanel .des {
  font-size: 1.67rem;
}
.renewAccessTokenSuccessfullyPanel ul.quick_links {
  list-style-type: decimal;
  margin: 10px 0 0 14px;
}
.renewAccessTokenSuccessfullyPanel ul.quick_links li a {
  font-size: 1.17rem;
  display: block;
  padding: 5px 0;
}
</style>
